/* navbarStyles.css */
.navbar a {
    color: white;
    font-weight: normal;
    text-decoration: none;
    transition: opacity 0.3s ease-in-out;
}

.nav-links {
    display: flex;
    gap: 2rem;
}

.navbar-link:hover {
    color: #CBC3E3;
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
}