/* Default styling for hero-image */
.hero-image {
    position: absolute;
    height: 95vh;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 40px; /* 40px left offset */
}

/* Additional styles for small screens if needed */
@media (max-width: 1200px) {
    .hero-image {
        /* Add specific styles for small screens here */
        --shift-value: 20px; /* Define a variable for the shift value */

        width: calc(100% + var(--shift-value)); /* Adjust the width using the variable */
        object-fit: cover;
        object-position: left top; /* Set the desired position */
        margin-left: calc(-1.1 * var(--shift-value)); /* Compensate with negative margin */
    }
}
