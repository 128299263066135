.App {
    text-align: center;
    background-color: black;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;

}

