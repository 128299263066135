/* Add styles for Masonry layout */
.gallery-masonry-grid {
    display: flex;
    /* margin-left: -10px; Adjust for the gutter, set in the masonry options */
    width: auto;
    justify-content: center;
}

.gallery-masonry-grid_column {
    background-clip: padding-box;

}

.gallery-masonry-item {
    padding-inline: 10px;
    padding-bottom: 8px;
    transition: transform 0.3s ease; /* Smooth transition effect */
}

.gallery-masonry-item:hover {
    transform: scale(1.02); /* Scale up by 10% on hover */
}


/* Responsive styles using media queries */
@media (min-width: 700px) {
    .gallery-masonry-grid_column {
        flex: 0 0 calc(50% - 10px); /* Two columns with a gap of 10px */
        /* max-width: calc(50% - 10px); */
    }
}

@media (min-width: 1100px) {
    .gallery-masonry-grid_column {
        flex: 0 0 calc(33.33% - 10px); /* Three columns with a gap of 10px */
        /* max-width: calc(33.33% - 10px); */
    }
}

@media (max-width: 699px) {
    .gallery-masonry-grid_column {
        flex: 0 0 calc(50% - 10px); /* Two columns with a gap of 10px */
        /* max-width: calc(50% - 10px); */
    }
}

@media (max-width: 499px) {
    .gallery-masonry-grid_column {
        flex: 0 0 calc(100% - 10px); /* One column with a gap of 10px */
        /* max-width: calc(100% - 10px); */
    }
}