/* Add styles for Masonry layout */
.my-masonry-grid {
    display: flex;
    /* margin-left: -10px; Adjust for the gutter, set in the masonry options */
    width: auto;
    justify-content: center;
}

.my-masonry-grid_column {
    background-clip: padding-box;


}

.masonry-item {
    padding-inline: 10px;
}

/* Responsive styles using media queries */
@media (min-width: 700px) {
    .my-masonry-grid_column {
        flex: 0 0 calc(50% - 10px); /* Two columns with a gap of 10px */
        /* max-width: calc(50% - 10px); */
    }
}

@media (min-width: 1100px) {
    .my-masonry-grid_column {
        flex: 0 0 calc(33.33% - 10px); /* Three columns with a gap of 10px */
        /* max-width: calc(33.33% - 10px); */
    }
}

@media (max-width: 699px) {
    .my-masonry-grid_column {
        flex: 0 0 calc(50% - 10px); /* Two columns with a gap of 10px */
        /* max-width: calc(50% - 10px); */
    }
}

@media (max-width: 499px) {
    .my-masonry-grid_column {
        flex: 0 0 calc(100% - 10px); /* One column with a gap of 10px */
        /* max-width: calc(100% - 10px); */
    }
}